"use client";

// import { getAOSAnimation } from "@litonarefin/utils/getAOSAnimation";
import { getBorderCSS } from "@litonarefin/utils/getBorderCSS";
import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";
import { FSCheckout } from "freemius-checkout-js";
import { useState, useEffect, Fragment } from "react";

const FreemiusButton = ({ attributes }) => {
    const {
        pricingTableId,
        productName,
        pluginId,
        billingCycle,
        publicKey,
        planId,
        isCoupon,
        couponCode,
        numberOfLicenses,
        productImageURL,
        btnColor,
        btnBgColor,
        border,
        borderRadius,
        padding,
        width,
    } = attributes;

    // const allAOS = getAOSAnimation(attributes);

    const checkoutConfig = new FSCheckout({
        plugin_id: pluginId,
        public_key: publicKey,
        plan_id: planId,
        image: productImageURL,
    });

    const [fsCheckout] = useState(checkoutConfig);

    const getCheckout = (e) => {
        e.preventDefault();
        fsCheckout.open({
            billing_cycle: billingCycle,
            plan_id: planId,
            licenses: numberOfLicenses,
            title: productName,
            coupon: couponCode,
            hide_coupon: isCoupon,
            success: (data) => {},
        });
    };

    useEffect(() => {
        // close and destroy the DOM related stuff on unmount
        return () => {
            fsCheckout.close();
            // fsCheckout.destroy();
        };
    }, [fsCheckout]);

    return (
        <Fragment>
            <button
                className="jlt-freemius-btn jlt-shadow-[0px_0px_20px_rgba(173,173,188,0.15)] jlt-rounded-[25px] jlt-py-3 jlt-px-6 jlt-font-bold jlt-text-sm jlt-leading-[18px] jlt-text-center jlt-text-white jlt-border-none jlt-cursor-pointer"
                // {...allAOS}
                onClick={(e) => getCheckout(e)}
                id={pricingTableId}>
                {attributes?.btnText || "Button Text"}
            </button>
            <style jsx>{`
                .jlt-freemius-btn {
                    ${btnBgColor ? `background: ${btnBgColor};` : ""}
                    ${btnColor ? `color: ${btnColor};` : ""}
                    ${borderRadius ? `border-radius: ${borderRadius}px;` : ""}
                    ${width ? `width: ${width};` : ""}
                    ${getBorderCSS(border) ? getBorderCSS(border) : ""}
                    ${getDimensionCSS(padding, "padding")
                        ? getDimensionCSS(padding, "padding")
                        : ""}
                }
            `}</style>
        </Fragment>
    );
};

export default FreemiusButton;
